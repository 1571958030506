import React from "react"
import { Link, graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import FluidContainer from "../components/fluidContainer"
import ContentContainer from "../components/contentContainer"
import ReactMarkdown from "react-markdown/with-html"
import { Header1, Subheading } from "../components/headings"
import moment from "moment"
import SEO from "../components/seo"

const ArticleContainer = styled.div`
  background-color: #eaecec;
  padding: 2rem;
  font-family: roboto;
  font-size: 16px;
  font-weight: 300;
  color: #333;
  iframe {
    width: 100%;
  }
`

const HeadingContainer = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  img {
    height: 100%;
  }
  @media (max-width: 768px) {
    display: block;
  }
`

const ArticleTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.strapiArticles.title}
      keywords={[`eating`, `contests`, `official`]}
    />
    <FluidContainer>
      <ContentContainer>
        <ArticleContainer>
          <HeadingContainer>
            <div>
              <Header1>{data.strapiArticles.title}</Header1>
              <Subheading>
                {moment(data.strapiArticles.createdAt).format("MM-DD-YYYY")}
              </Subheading>
            </div>
          </HeadingContainer>
          <ReactMarkdown
            escapeHtml={false}
            source={data.strapiArticles.content}
          />
        </ArticleContainer>
      </ContentContainer>
    </FluidContainer>
  </Layout>
)

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($sn: Int!) {
    strapiArticles(sn: { eq: $sn }) {
      title
      createdAt
      content
    }
  }
`
